import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`15th October 2021`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">{`You can now customize HTTP/1 header names on the server side
using `}<a parentName="li" {...{
          "href": "type://ServerBuilder#http1HeaderNaming(Http1HeaderNaming):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#http1HeaderNaming(com.linecorp.armeria.common.Http1HeaderNaming)"
        }}>{`type://ServerBuilder#http1HeaderNaming(Http1HeaderNaming)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3845"
        }}>{`#3845`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3866"
        }}>{`#3866`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .http1HeaderNaming(Http1HeaderNaming.traditional());
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now easily create an `}<a parentName="li" {...{
          "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
        }}>{`type://HttpResponse`}</a>{` from a specific executor
using `}<a parentName="li" {...{
          "href": "type://HttpResponse#from(Supplier,Executor):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#from(java.util.function.Supplier,java.util.concurrent.Executor)"
        }}>{`type://HttpResponse#from(Supplier,Executor)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3834"
        }}>{`#3834`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3864"
        }}>{`#3864`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .service("/blocking" (ctx, req) -> {
        return HttpResponse.from(() -> { // 👈👈👈
            // Perform a long running task.
            ...
            return HttpResponse.of(...);
        }, ctx.blockingTaskExecutor());
      });
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Various `}<a parentName="li" {...{
          "href": "type://HttpHeaderNames:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpHeaderNames.html"
        }}>{`type://HttpHeaderNames`}</a>{` are added. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3872"
        }}>{`#3872`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`You no longer see a `}<inlineCode parentName="li">{`NullPointerExcepiton`}</inlineCode>{` when no suitable `}<a parentName="li" {...{
          "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
        }}>{`type://ClientFactory`}</a>{` was found. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3867"
        }}>{`#3867`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3868"
        }}>{`#3868`}</a>
        <ul parentName="li">
          <li parentName="ul">{`An `}<inlineCode parentName="li">{`IllegalStateException`}</inlineCode>{` with a proper error message will be raised instead.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`You no longer see a false positive warning when adding `}<a parentName="li" {...{
          "href": "typeplural://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`typeplural://GrpcService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3869"
        }}>{`#3869`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3871"
        }}>{`#3871`}</a></li>
      <li parentName="ul">{`You no longer see an `}<inlineCode parentName="li">{`IllegalStateException`}</inlineCode>{` when a gRPC client call starts with
an empty `}<a parentName="li" {...{
          "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
        }}>{`type://EndpointGroup`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3876"
        }}>{`#3876`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.spring.io/spring-boot/docs/current/reference/html/actuator.html#actuator.monitoring.customizing-management-server-port"
        }}><inlineCode parentName="a">{`management.server.port`}</inlineCode></a>{`
is now correctly activated only when Armeria Actuator is configured. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3873"
        }}>{`#3873`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GraphqlRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/graphql/protocol/GraphqlRequest.html"
        }}>{`type://GraphqlRequest`}</a>{` has been moved to `}<inlineCode parentName="li">{`common`}</inlineCode>{` from `}<inlineCode parentName="li">{`server`}</inlineCode>{` package. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3865"
        }}>{`#3865`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Netty 4.1.68 → 4.1.69`}</li>
      <li parentName="ul">{`Netty io_uring 0.0.8 → 0.0.9`}</li>
      <li parentName="ul">{`Reactor 3.4.10 → 3.4.11`}</li>
      <li parentName="ul">{`reactor-kotlin-extensions 1.1.4 → 1.1.5`}</li>
      <li parentName="ul">{`RxJava 3.1.1 → 3.1.2`}</li>
      <li parentName="ul">{`ScalaPB 0.11.5 → 0.11.6`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['ghkim3221', 'heowc', 'hyangtack', 'ikhoon', 'jupiny', 'mauhiz', 'minwoox', 'octo47', 'TheWeaVer', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      